import React, { useState } from 'react'
import Photo from './Photo'
import ModalPhoto from './ModalPhoto'

import './Gallery.scss'

function Gallery() {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <section className="gallery">
      <h2 className="galleryTitle">Наши работы</h2>
      <Photo setSelectedImg={setSelectedImg}/>
      {selectedImg && <ModalPhoto selectedImg={selectedImg} setSelectedImg={setSelectedImg}/> }
    </section>
  )
}

export default Gallery
