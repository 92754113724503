import React, { useState, useRef, useEffect } from 'react'
import ModalForm from '../modalForm/ModalForm'
import lottie from 'lottie-web'
import './Title.scss'

import LogoImg from '../../../images/logo/blue.svg'

function Title() {
  const [showModal, setShowModal] = useState(false);
  const [scroll, setScroll] = useState(false);
  const AnimationRef = useRef(null)

  const hideScroll = () => {
    if(window.scrollY >= 100){
      setScroll(true);
    }
    else{
      setScroll(false);
    }
  }

  useEffect(() => {
    lottie.loadAnimation({
      container: AnimationRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../animation/scroll-down-animation.json'),
      rendererSettings: {
        progressiveLoad: true,
        hideOnTransparent: true,
        className: 'lottieScroll',
      }
    });

    window.addEventListener('scroll', hideScroll)
  }, [])

  return (
    <>
      <ModalForm
        messageForm="Опишите заказ"
        headerForm="Оставьте заявку"
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="title">
        <div className="mobileLogo"><img src={LogoImg} alt="Логотип RealElement" /></div>
        <h1 className="titleBlue">Наружная реклама</h1>
        <h1 className="titleBorder">Дизайн и печать промо</h1>
        <h3 className="title__description">Мы умеем работать в сжатые сроки, реализовывая проекты <br />любой сложности на протяжении 20 лет.</h3>
        <button onClick={() => setShowModal(true)} className="titleFooter__callback">Консультация</button>
      </div>
      <div className={scroll ? "scrollDown hide" : "scrollDown"}>
        <p>листать</p>
        <div className="lottieArrow" ref={AnimationRef}/>
      </div>
    </>
  )
}

export default Title