import * as React from "react"
import '../styles/style.scss'
import '../styles/mainPage.scss'

import Seo from '../component/Seo'
import Layout from '../templates/Layout'
import Navigation from '../component/navigation/Navigation'
import MainContent from '../component/mainPage/MainContent'


const IndexPage = () => {
  return (
    <div className="mainPage">
      <Seo title="Наружная реклама и дизайн" />
      <Navigation />
      <Layout styleName="mainContent">
        <MainContent />
      </Layout>
    </div>
  )
}

export default IndexPage
