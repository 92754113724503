import React from 'react'

import Title from './title/Title'
import Services from './servicesBlock/ServicesBlock'
import Gallery from './gallerySection/Gallery'
import Partner from './partnerSection/PartnerSection'
import Callback from './callbackSection/CallbackSection'

function MainContent() {
  return (
    <>
    <section className="titleSection">
      <Title />
    </section>
    <Services />
    <Gallery />
    <Partner />
    <Callback />
    </>
  )
}

export default MainContent
