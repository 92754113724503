import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './PartnerSection.scss'

function PartnerSection() {
  return (
    <section className="partners">
      <h2 className="partnersTitle">Наши клиенты</h2>
      <div className="container blockPartners">
        <StaticImage src="../../../images/partners/Sbarri.png" alt="Логотип sbarro" width='200'/>
        <StaticImage src="../../../images/partners/southcoast.png" alt="Логотип Южный берег" width='200' />
        <StaticImage src="../../../images/partners/airport.png" alt="Логотип Аэропорт Красноярск" width='200' />
        <StaticImage src="../../../images/partners/cinnabon.png" alt="Логотип Синнабон" width='200' />
        <StaticImage src="../../../images/partners/sfu.png" alt="Логотип СФУ" width='200' />
        <StaticImage src="../../../images/partners/dorc.png" alt="Логотип Дорц" width='200' />
        <StaticImage src="../../../images/partners/sushiwok.png" alt="Логотип СушиWok" width='200' />
        <StaticImage src="../../../images/partners/toyota.png" alt="Логотип Toyota" width='200' />
        <StaticImage src="../../../images/partners/partner-sibcem.png" alt="Логотип Сибирский цемент" width='200' />
      </div>
    </section>
  )
}

export default PartnerSection
