import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'

function ModalPhoto({ selectedImg, setSelectedImg }) {

  const handleClick = (e) => {
    setSelectedImg(null);
  }

  return (
    <motion.div className="backdrop"
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <motion.div
        initial={{ scale: 0.95, y: 15, opacity: 0 }}
        animate={{ scale: 1, y: 0, opacity: 1 }}
        transition={{delay: 0.3}}
      >
        <GatsbyImage image={selectedImg} alt="Фото галереи" className="photo" />
      </motion.div>
    </motion.div>
  )
}

export default ModalPhoto
