import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import './ModalForm.scss'
import {modalContainer, modalButton, modalForm} from './animation'
import Form from '../callbackForm/CallbackForm'

import close from '../../../images/icon/close.svg'

function ModalForm({ showModal, setShowModal} ) {
  return (
  <AnimatePresence exitBeforeEnter>
    { showModal && (
      <motion.div 
      className="modalContainer"
      variants={modalContainer}
      initial="out"
      animate="in"
      exit="out"
      transition={modalContainer.transition}
      >
        <motion.button 
          className="closeForm" 
          variants={modalButton}
          initial="out"
          animate="in"
          transition={modalButton.transition}
          onClick={() => setShowModal(false)}
        >
          <img src={close} alt="close"/>
        </motion.button>

      <motion.div
        className="blockForm" 
        variants={modalForm}
        initial="out"
        animate="in"
        transition={modalForm.transition}
      >
        <Form title="Оставьте заявку и специалист свяжется с Вами" button="Отправить" message="Комментарии к заявке"/>
      </motion.div>
    </motion.div>
    )}
  </AnimatePresence>
  )
}

export default ModalForm
