import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

function Photo({ setSelectedImg }) {
  const [mediaData, setMediaData] = useState([]);

  const data = useStaticQuery(graphql`
    {
      allGalleryDataJson {
        nodes {
          desktop {
            id
            url {
              childImageSharp {
                gatsbyImageData(
                  height: 800
                  formats: [AUTO, WEBP]
                  placeholder: TRACED_SVG
                  quality: 85
                )
              }
            }
          }
          phone {
            id
            url {
              childImageSharp {
                gatsbyImageData(
                  width: 450
                  formats: [AUTO, WEBP]
                  placeholder: TRACED_SVG
                  quality: 85
                )
              }
            }
          }
        }
      }
    }
  `)

  const { desktop, phone } = data.allGalleryDataJson.nodes[0];


  useEffect(() => {
    const changePhotos = () => {
      if (window.innerWidth <= 600) {
        setMediaData(phone);
      }
      else {
        setMediaData(desktop);
      }
    }

    changePhotos();

    window.addEventListener('resize', changePhotos)
  }, [desktop, phone])

  return (
    <div className="galleryContainer">
      {mediaData.map(galleryDesktop => {
        const { id, url } = galleryDesktop;
        const imgSrc = getImage(url);

        return (
          <div className="photoContainer"
            id={id}
            onClick={() => setSelectedImg(imgSrc)}
            onKeyDown={() => setSelectedImg(imgSrc)}
            role="button"
            tabIndex={id}>
            <GatsbyImage image={imgSrc} alt="Фото для галлереи" id={id} className="photo" />
          </div>
        )
      })}
    </div>
  )
}

export default Photo
