import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import './ServicesBlock.scss'

function ServicesBlock() {
  const data = useStaticQuery(graphql`
  {
    allDesignDataJson {
      nodes {
        id
        name
        url
      }
    }
    allInteriorDataJson {
      nodes {
        name
        id
        url
      }
    }
    allMobileDataJson {
      nodes {
        name
        id
        url
      }
    }
    allOutdoorDataJson {
      nodes {
        name
        id
        url
      }
    }
  }
`)


  return (
    <section className="services">
      <h2>Давайте вместе <span>создавать<br/>эффективную рекламу</span></h2>
      <p className="description">Мы занимаемся наружной рекламой уже более 20 лет.</p>
      <p>Наша компания не стоит на месте, поэтому мы открыли ещё одно направление - дизайн и полиграфия.
      В штате работают специалисты, которые знают толк в эффективной рекламе и могут не только сконструировать, но и создать макеты под ваш запрос, будь то макет визитки или баннера.</p>
      <div className="services__block">
        <div className="direction">
        <Link to="/outdoor/">Информационные вывески</Link>
        {data.allOutdoorDataJson.nodes.map(outdoorInfo => {
          const { name, id, url } = outdoorInfo;

          return <Link to={`/outdoor/${url}`} key={id}>{name}</Link>
        })}
        </div>
        <div className="direction">
        <Link to="/outdoor/">Интерьерное оформление</Link>
        {data.allInteriorDataJson.nodes.map(interiorInfo => {
          const { name, id, url } = interiorInfo;

          return <Link to={`/outdoor/${url}`} key={id}>{name}</Link>
        })}
        </div>
        <div className="direction">
        <Link to="/outdoor/">Мобильные конструкции</Link>
        {data.allMobileDataJson.nodes.map(mobileInfo => {
          const { name, id, url } = mobileInfo;

          return <Link to={`/outdoor/${url}`} key={id}>{name}</Link>
        })}
        </div>
        <div className="direction">
        <Link to="/design/">Дизайн и промо</Link>
        {data.allDesignDataJson.nodes.map(designInfo => {
          const { name, id, url } = designInfo;

          return <Link to={`/design/${url}`} key={id}>{name}</Link>
        })}
        </div>
      </div>
    </section>
  )
}

export default ServicesBlock
